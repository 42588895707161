import { Text } from "@your-org/ui-lib";

export function ScrollTopButton() {
  function scrollTop() {
    window.scroll({ top: 0 });
  }

  return (
    <div className="fixed z-10 bottom-4 right-4 cursor-pointer">
      <div
        className="w-8 h-8 bg-offwhite-dark hover:bg-offwhite-dark dark:bg-offblack-normal dark:hover:bg-offblack-light rounded-3xl flex items-center justify-center"
        onClick={scrollTop}
      >
        <Text>↑</Text>
      </div>
    </div>
  );
}
