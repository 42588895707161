import Button, { ButtonVariants } from '../Button';
import { Delete } from '../../icons';
import { memo } from 'react';
import { useFilter } from '../../hooks/useFilter';

interface Props {
  filter: string;
  label?: string;
  variant?: ButtonVariants;
}
/**
 * New version of RemoveFilterButton
 * - useMemo
 * - the difference to RemoveFilterButton is that it add a "value" to the removeFilter function. This is needed for the groups filter.
 * @param filter query parameter
 * @param label text in front of the value
 * @param variant the old button follows the global schema
 * @constructor
 */
function RemoveMultiFilterButton({ filter, label = '', variant = 'black' }: Props) {
  const { selectedFilter, removeFilter } = useFilter(filter);

  if (!selectedFilter) return null;

  const B = (text: string, value: string | undefined) => (
    <Button
      key={text}
      className="flex items-center"
      onClick={() => removeFilter(value)}
      variant={variant}
    >
      {`${label}${text}`}
      <div className="mr-2" />
      <Delete />
    </Button>
  );

  if (Array.isArray(selectedFilter)) return <>{selectedFilter.map(f => B(f, f))}</>;
  return B(selectedFilter, undefined);
}

// parent is refreshing every 3 second ...
export default memo(RemoveMultiFilterButton, () => true);
