import { useRef, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import Input, { InputVariant } from '../Input';
import { updateQueryParams } from '../../hooks/useFilter';

export interface SearchInputProps {
  /**
   * text to shown in the input while empty
   */
  placeholder?: string;
  /**
   * Same as Input variants
   */
  variant?: InputVariant;
  /**
   * name of the filter to add to query params, default 'searchKey'
   */
  filter?: 'searchKey' | 'search';
}

/**
 * Input to search for a specific value, add value will be added to query params
 *
 * @param {string} placeholder
 * @param {InputVariant} variant
 * @param filter
 * @constructor
 */
export function SearchInput({
  placeholder = 'Search for title, artist, ISRC or Video ID ...',
  variant = 'gray',
  filter = 'searchKey',
}: SearchInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const searchKey = router && router.query[filter];

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && ref.current) {
      ref.current.blur();
    }
  }

  function setSearchKey() {
    if (ref?.current?.value?.length) {
      console.log('SEARCH', ref.current.value);
      router.query[filter] = ref.current.value;
      updateQueryParams(router);
    } else if (router.query[filter]) {
      // delete key
      delete router.query[filter];
      updateQueryParams(router);
    }
  }

  return (
    <Input
      id="generic-search-input"
      ref={ref}
      variant={variant}
      onKeyUp={handleKeyUp}
      onBlur={setSearchKey}
      type="search"
      defaultValue={searchKey}
      placeholder={placeholder}
      // className="mt-2"
    />
  );
}

export default SearchInput;
