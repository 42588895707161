import {
  Button,
  FilterModal,
  Input,
  RemoveMultiFilterButton,
  Row,
  SearchInput,
  Text,
  useFilter,
} from "@your-org/ui-lib";
import { MainLayout } from "@/components/layout";
import SortButton from "@/components/filter/SortButton";
import { SelectCW2 } from "@/components/filter/SelectCW";
import { FILTER, ProductOrderByKeys } from "@/lib/const";
import { useProducts } from "../hooks/useProducts";
import { ScrapingGrid } from "@/components/ScrapingGrid/ScrapingGrid";
import { useLabels } from "../hooks/useLabels";
import toast from "react-hot-toast";
import { useClipboard } from "@mantine/hooks";
import { StatusInfoWrapped } from "@/components/StatusInfo";
import { getWeekNumber } from "../util";
import { ChangeEvent, Fragment, useState } from "react";
import { NoResults } from "@/components/NoResults";
import { ScrollTopButton } from "@/components/ScrollTopButton";
import LoadingProgress from "@/components/LoadingProgress";
import OptionSelect from "@/components/OptionsSelect";


function LabelFilter() {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const { labels } = useLabels();

  const { updateFilter } = useFilter(FILTER.LABEL);

  function selectOption(value: ProductOrderByKeys) {
    console.log("selectOption", value);
    setFilter("");
    setOpen(false);
    if (value) updateFilter(value);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value);
  }

  function filterLabels(label: string) {
    return label.trim().toLowerCase().includes(filter.trim().toLowerCase());
  }

  const filteredLabels = filter.length ? labels.filter(filterLabels) : labels;

  return (
    <div className="relative">
      <Input
        className="w-12"
        type="search"
        placeholder="Select a label"
        onChange={handleChange}
        id="test-label"
        onFocus={() => setOpen(true)}
      />
      <FilterModal
        className="min-w-30 !mt-1 max-h-80 overflow-scroll"
        show={open}
        close={() => setOpen(false)}
        background="gray"
        showClose={false}
      >
        <div className="p-2">
          {filteredLabels.map((label) => (
            <OptionSelect select={() => selectOption(label)} value={label} key={label} />
          ))}
          {!filteredLabels.length && "No matching"}
        </div>
      </FilterModal>
    </div>
  );
}

type CWLabelProps = { date: Date };

// TODO: We want monday as the start of the week,
// TODO: but friday as the displayed date
function CWLabel({ date }: CWLabelProps) {
  const localeString = date.toLocaleDateString("de-DE", {
    weekday: "short",
    month: "2-digit",
    day: "numeric",
  });
  const formatted = localeString.slice(0, 2) + localeString.slice(3); // remove dot after weekday

  const id = `${date.getFullYear()}-W${getWeekNumber(date)}`;
  return (
    <div className="flex mt-[35px] mb-[25px] scroll-m-6" id={id}>
      <Text variant="link">#</Text>
      <Text className="mx-[5px]" variant="h3">
        {getWeekNumber(date)}
      </Text>
      <Text variant="link">{formatted}</Text>
    </div>
  );
}

function CalenderWeekPage() {
  const { weeks, cws, loading, order, orderBy } = useProducts();
  const [showRelatedProducts, setShowRelatedProducts] = useState(false);
  const clipboard = useClipboard();

  function handleShare() {
    const currentUrl = window.location.href;
    const url = `${currentUrl}${currentUrl.includes("?") ? "&" : "?"}share=1`;
    console.log("f:share", url, currentUrl);
    clipboard.copy(url);

    toast("URL copied to clipboard!", {
      icon: "🔗",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  }

  // const minCW = weeks.length
  //   ? `${weeks[0][0].year}-W${weeks[0][0].cw}`
  //   : undefined;
  // const maxCW = weeks.length
  //   ? `${weeks[weeks.length - 1][0].year}-W${weeks[weeks.length - 1][0].cw}`
  //   : undefined;

  const currentCount = weeks.reduce((p, c) => p + c.length, 0);

  return (
    <MainLayout>
      <StatusInfoWrapped currentCount={currentCount} title="CALENDAR WEEK" />
      <div className="mb-6" />
      <Row>
        <div className="flex-1 flex items-end justify-end space-x-2.5 space-y-1 flex-wrap">
          <SortButton order={order} orderBy={orderBy} />
          <LabelFilter />
          <SelectCW2 cws={cws} />
          <Button variant="gray" onClick={handleShare}>
            Share↑
          </Button>
          <Button variant="gray" onClick={() => setShowRelatedProducts(s => !s)} toggled={showRelatedProducts}>
            {showRelatedProducts ? "Hide" : "Show"} related products
          </Button>
          <SearchInput
            filter="search"
            variant="gray"
            placeholder="Search: title  /  artist  /  asin"
          />
        </div>
      </Row>
      <div className="flex gap-2 flex-wrap">
        <RemoveMultiFilterButton filter={FILTER.LABEL} variant="gray" />
      </div>

      {loading && <LoadingProgress />}
      {weeks?.map((products) => (
        <Fragment key={products[0].asin}>
          <CWLabel date={new Date(products[0].releaseDate)} />
          <ScrapingGrid loading={loading} products={products} showRelatedProducts={showRelatedProducts} />
        </Fragment>
      ))}

      {!loading && !weeks.length && (
        <NoResults
          description="Unfortunately we could not find any data matching your search. Please try looking for something else" />
      )}
      <ScrollTopButton />
    </MainLayout>
  );
}

CalenderWeekPage.secured = true;

export default CalenderWeekPage;
