import { MouseEventHandler } from 'react';

interface IconProps {
  onClick?: MouseEventHandler<SVGSVGElement>;
}
/**
 *
 * @param onClick
 * @param variant
 * @constructor
 */
export const Delete = ({ onClick }: IconProps) => {
  return (
    <svg
      onClick={onClick}
      className="mx-1"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.71053 4.71653L6.5 6.506L8.28947 4.71653L8.73684 5.1639L6.94737 6.95337L8.73684 8.74284L8.28947 9.19021L6.5 7.40074L4.71053 9.19021L4.26316 8.74284L6.05263 6.95337L4.26316 5.1639L4.71053 4.71653ZM10.75 2.70337C11.8772 3.83054 12.5104 5.35931 12.5104 6.95337C12.5104 8.54743 11.8772 10.0762 10.75 11.2034C9.62283 12.3305 8.09406 12.9638 6.5 12.9638C4.90594 12.9638 3.37717 12.3305 2.25 11.2034C1.12283 10.0762 0.489592 8.54743 0.489593 6.95337C0.489592 5.35931 1.12283 3.83054 2.25 2.70337C3.37717 1.5762 4.90594 0.942961 6.5 0.942962C8.09406 0.942961 9.62283 1.5762 10.75 2.70337ZM10.3026 3.15074C9.29411 2.14222 7.92626 1.57564 6.5 1.57564C5.07374 1.57564 3.70589 2.14222 2.69737 3.15074C1.68885 4.15926 1.12227 5.52711 1.12227 6.95337C1.12227 8.37963 1.68885 9.74748 2.69737 10.756C3.70589 11.7645 5.07374 12.3311 6.5 12.3311C7.92626 12.3311 9.29411 11.7645 10.3026 10.756C11.3112 9.74748 11.8777 8.37963 11.8777 6.95337C11.8777 5.52711 11.3112 4.15926 10.3026 3.15074Z" />
    </svg>
  );
};

export default Delete;
