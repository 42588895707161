import { ParsedUrlQuery } from "querystring";

export function getSearchParams(keys: string[], query: ParsedUrlQuery): string {
  console.log('f:getSearchParams', keys, query);
  const newQuery = new URLSearchParams();
  keys.forEach(key => {
    const value = query[key];
    // console.log(key, value);
    if (!value) return;
    if (typeof value === 'string') newQuery.append(key, value);
    else value.map(v => newQuery.append(key, v));
  });
  return newQuery.toString();
}
