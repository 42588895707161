import { useRouter } from "next/router";
import { FILTER } from "@/lib/const";
import useSWR from "swr";
import { getSearchParams } from "./index";
import { LabelsData } from "@/pages/api/product/labels";
import { JsonApiSuccessResponse } from "@your-org/core-lib";

export type LabelsResponse = JsonApiSuccessResponse<LabelsData>

export function useLabels() {
  const router = useRouter();

  const keys = [FILTER.SEARCH];
  const url = `/api/product/labels?${getSearchParams(
    keys,
    router.query,
  )}&take=2000&skip=0`;
  const { data, error, isValidating } = useSWR<LabelsResponse>(url);
  console.log("hook:useLabels,", data, error, url);

  const loading = !data;
  const labels = data ? data.data.labels : [];


  return {
    labels,
    loading,
    validating: isValidating,
    error,
  };
}
