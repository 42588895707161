import { Button, FilterModal } from "@your-org/ui-lib";
import { useState } from "react";
import { ORDER_BY, ProductOrderByKeys } from "@/lib/const";
import OptionSelect from "@/components/OptionsSelect";

type SortOption = {
  text: string;
  value: ProductOrderByKeys;
};

const SORT_OPTIONS: SortOption[] = [
  { text: "Artist", value: ORDER_BY.PRODUCT.ARTIST },
  { text: "Current rank", value: ORDER_BY.PRODUCT.CURRENT_POSITION },
  { text: "Highest rank", value: ORDER_BY.PRODUCT.HIGHEST_POSITION },
  { text: "Ranked days", value: ORDER_BY.PRODUCT.RANKED_DAYS },
];

type SortButtonProps = {
  order: (key: ProductOrderByKeys) => void;
  orderBy: ProductOrderByKeys;
};

/**
 *
 ✨ * @constructor
 */
function SortButton({ order, orderBy }: SortButtonProps) {
  const [open, setOpen] = useState(false);

  function selectOption(value: ProductOrderByKeys) {
    order(value);
    setOpen(false);
  }

  const text =
    SORT_OPTIONS.find((o) => o.value === orderBy)?.text || SORT_OPTIONS[0].text;
  return (
    <div className="relative">
      <FilterModal
        show={open}
        close={() => setOpen(false)}
        background="gray"
        showClose={false}
      >
        <div className="space-y-1 p-2">
          {SORT_OPTIONS.map((opt, i) => (
            <OptionSelect key={i} select={() => selectOption(opt.value)} value={opt.text} />
          ))}
        </div>
      </FilterModal>
      <Button variant="gray" onClick={() => setOpen(true)}>
        Sort: {text}
      </Button>
    </div>
  );
}

export default SortButton;
