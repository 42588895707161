import { Button, FilterModal, Input } from "@your-org/ui-lib";
import { ChangeEvent, useRef, useState } from "react";
import OptionSelect from "@/components/OptionsSelect";

/**
 *
 * TODO: Add min max values based on visible CW's*
 * @constructor
 */
function SelectCW({ min, max }: { min?: string, max?: string }) {
  const [selectedCW, setSelectedCW] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    console.log("SelectCW:handleChange", e.target.value);
    setSelectedCW(value);
    if (value) {
      // note the picker has min/max that's why the range is capped
      const week = document.getElementById(value);
      week?.scrollIntoView();
    }
  }

  function showCWPicker() {
    console.log("openModal", ref.current);
    // @ts-ignore
    ref.current?.showPicker();
  }

  return (
    <div>
      <div className="invisible w-0 -mt-10">
        <Input
          ref={ref}
          type="week"
          id="filter-cw"
          name="filter-cw"
          onChange={handleChange}
          value={selectedCW}
          min={min}
          max={max}
        />
      </div>
      <Button variant="gray" onClick={showCWPicker}>Select CW</Button>
    </div>
  );
}


function transformCW(cw: string) {
  const [year, week] = cw.split("-W");
  return `${week} ('${year.slice(2, 4)})`;
}

export function SelectCW2({ cws = [] }: { cws: string[] }) {
  const [open, setOpen] = useState(false);

  function selectOption(value: string) {
    console.log("SelectCW2:handleChange", value);

    setOpen(false);

    const week = document.getElementById(value);
    week?.scrollIntoView();
  }

  return (
    <div className="relative">
      <FilterModal
        show={open}
        close={() => setOpen(false)}
        background="gray"
        showClose={false}
      >
        <div className="space-y-1 p-2 w-20">
          {cws.map((opt, i) => (
            <OptionSelect key={i} select={() => selectOption(`${opt}`)} value={transformCW(opt)} />
          ))}
        </div>
      </FilterModal>
      <Button variant="gray" onClick={() => setOpen(true)}>
        CW
      </Button>
    </div>

  );
}

export default SelectCW;
