export function isToday(dateRaw: Date) {
  const date = new Date(dateRaw);
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

/**
 * get the year from a date
 * @param {Date} date
 */
export function getYear(date: Date): number {
  return date.getFullYear();
}

/**
 * calc the number of the week, doesn't madder in witch yeat
 * @param {Date} dateRaw
 */
export function getWeekNumber(dateRaw: Date): number {
  const date = new Date(dateRaw);
  const firstJanuary = new Date(date.getFullYear(), 0, 1);
  const days = Math.ceil((date.valueOf() - firstJanuary.valueOf()) / (24 * 60 * 60 * 1000));
  return Math.ceil(days / 7);
}

/**
 * a date is "new" if it is in the last 7 days
 * @param {Date} dateRaw
 */
export const calcIsNew = (dateRaw: Date) => {
  const date = new Date(dateRaw);
  const sevenDaysAgo = new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000);
  return date.getTime() >= sevenDaysAgo.getTime();
};

// TODO: Adjust to monday is this is supposed to be used for sorting
// TODO: Possibly use function to actually get the week's date for friday for displaying
// export function getLastFriday(date: Date = new Date()) {
//   const day = date.getDay();

//   if (day === 5) { // 5 === friday
//     return date;
//   } else if (day === 6) {
//     new Date(date.setDate(date.getDate() - 1));
//   } else {
//     new Date(date.setDate(date.getDate() - (2 + day)));
//   }
// }
