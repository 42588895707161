import cn from 'classnames';
import { forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import s from './Input.module.css';

export type InputVariant = 'gray' | 'theme';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * always give id's to an input!
   */
  id: string;
  /**
   * type fot the input, default ist 'text'
   */
  type?: HTMLInputTypeAttribute;
  /**
   * change the style of the background
   * - gray - for status page
   * - theme - one of 3 classes from old design
   */
  variant?: InputVariant;
  /**
   * not allow to input
   */
  disabled?: boolean;
  /**
   * add custom css classes
   */
  className?: string;
}

/**
 * Raw base UI component, should be used most time inside other components
 * @param props
 * @constructor
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { id, type = 'text', className, variant = 'gray', disabled = false, placeholder, ...rest },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.number]: type === 'number',
        [s.checkbox]: type === 'checkbox',
        [s.radio]: type === 'radio',
        'bg-theme': variant === 'theme',
        [s.gray]: variant === 'gray',
      },
      className,
    );

    return (
      <input
        id={id}
        ref={ref}
        type={type}
        className={rootClassName}
        disabled={disabled}
        placeholder={placeholder}
        {...rest}
      />
    );
  },
);

export default Input;
