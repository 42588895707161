import { useRouter } from "next/router";
import { FILTER, ORDER_BY, ProductOrderByKeys, SORT } from "@/lib/const";
import useSWR from "swr";
import { getSearchParams } from "./index";
import { useState } from "react";
import { ProductsData } from "@/pages/api/product";
import { JsonApiSuccessResponse } from "@your-org/core-lib";

export type ProductResponse = JsonApiSuccessResponse<ProductsData>;

export function useProducts(defaultOrder = ORDER_BY.PRODUCT.CURRENT_POSITION) {
  const router = useRouter();
  const [orderBy, setOrderBy] = useState(defaultOrder);
  // currently this is not used in the app
  // const [sort, setSort] = useState(SORT.UP);

  const keys = [FILTER.CW, FILTER.LABEL, FILTER.SEARCH, FILTER.STATUS];
  const url = `/api/product?${getSearchParams(keys, router.query)}&sort=${
    SORT.UP
  }&orderBy=${orderBy}&take=200&skip=0`;
  const { data, error, isValidating } = useSWR<ProductResponse>(url);
  console.log("hook:useProducts,", data, error, url);

  const loading = !data;
  const weeks = data ? data.data.weeks : [];
  const cws = data ? data.data.cws : [];

  /**
   * change both, sort order and the sort direction
   * @param key
   */
  function handleOrderBy(key: ProductOrderByKeys) {
    // same order key twice toggle sort
    // if (orderBy === key && sort === SORT.DOWN) setSort(SORT.UP);
    setOrderBy(key);
    // reset to sort down
    // if (sort === SORT.UP) setSort(SORT.DOWN);
  }

  return {
    weeks,
    cws,
    loading,
    validating: isValidating,
    error,
    orderBy,
    //sort, //
    order: handleOrderBy,
  };
}
